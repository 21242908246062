export const FirebaseConfig = {
	"projectId": "koteride",
	"appId": "1:275683535602:web:cd4d3f17a7a2496063fe6b",
	"databaseURL": "https://koteride-default-rtdb.firebaseio.com",
	"storageBucket": "koteride.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyBBeENisTaiWvd0iNN5gwdo0c2PwCjcbXA",
	"authDomain": "koteride.firebaseapp.com",
	"messagingSenderId": "275683535602",
	"measurementId": "G-6FM97NKSSX"
};